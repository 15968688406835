import { fetchJson, ResponseInterface } from "@saas/core";
import { MarketplaceListStatusEnum } from "@saas/shared/utils/@enum/marketplace-list-status/marketplace-list-status.enum";
import { MarketplaceTypeEnum } from "@saas/shared/utils/@enum/marketplace-type/marketplace-type.enum";
import { MarketplaceConnectionMetadataInterface } from "@saas/shared/utils/@interface/marketplace-connection-metadata/marketplace-connection-metadata.interface";
import { MarketplaceConnectionInterface } from "@saas/shared/utils/@interface/marketplace-connection/marketplace-connection.interface";
import { ChannelEnum } from "@saas/shared/utils/^enum/channel/channel";
import { cleanPayload } from "@saas/shared/utils/clean-payload/clean-payload";
import { mapToCamelCase } from "@saas/shared/utils/map-to-camel-case/map-to-camel-case";

import { stringify } from "qs";

export interface MarketplaceConnectionProvider
  extends MarketplaceConnectionInterface {
  channel: ChannelEnum.TOKOPEDIA | ChannelEnum.SHOPEE | ChannelEnum.TIKTOK;
}
interface GetMarketplaceConnectionListResponse extends ResponseInterface {
  data: ReadonlyArray<{
    id: number;
    channel: ChannelEnum;
    shop_id: string;
    shop_name: string;
    shop_url: string;
    status: MarketplaceListStatusEnum;
    is_notify: boolean;
    shop_label: string;
    is_first_connected: boolean;
    created_at: Date;
  }>;
  metadata: {
    connection_list: ReadonlyArray<{
      channel: ChannelEnum;
      status: MarketplaceListStatusEnum;
      maximum_quota: number;
      current_quota: number;
      connection_type_list: ReadonlyArray<{
        shop_id: string;
        shop_connection: string;
        chat_connection: string;
      }>;
    }>;
  };
}
export type GetMarketplaceConnectionListInput = {
  connectionType?: Array<MarketplaceTypeEnum>;
};
export type GetMarketplaceConnectionListOutput = {
  data: Array<MarketplaceConnectionInterface>;
  metadata: {
    connectionList: Array<MarketplaceConnectionMetadataInterface>;
  };
};
export const getMarketplaceConnections = async (
  input?: GetMarketplaceConnectionListInput,
  init?: RequestInit
): Promise<GetMarketplaceConnectionListOutput> => {
  const queryString = stringify(
    cleanPayload({
      connection_type: input?.connectionType,
    }),
    { arrayFormat: "comma" }
  );
  const response = await fetchJson<GetMarketplaceConnectionListResponse>(
    `/api/marketplace/marketplace-connections?` + queryString,
    init
  );
  return mapToCamelCase({ data: response.data, metadata: response.metadata });
};
export default getMarketplaceConnections;
