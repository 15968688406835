import { MarketplaceTypeEnum } from "../@enum/marketplace-type/marketplace-type.enum";

type Options = {
  metadata?: boolean;
  connectionType?: Array<MarketplaceTypeEnum>;
  shopId?: string;
};
export interface marketPlaceConnectionQueryKeys {
  all: (
    options?: Options
  ) => readonly ["marketPlaceConnection", Record<string, unknown>];
  resellerProfile: () => readonly ["marketPlaceConnection", "resellerProfile"];
}
export const marketplaceConnectionQueryKeys: marketPlaceConnectionQueryKeys = {
  all: (options) => ["marketPlaceConnection", { ...options }] as const,
  resellerProfile: () => ["marketPlaceConnection", "resellerProfile"] as const,
};
export default marketplaceConnectionQueryKeys;
