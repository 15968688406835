export type IAuthRoutes = ReadonlyArray<string>;

/**
 * Default authentication routes for the application.
 */
export const authRoutes: IAuthRoutes = [
  "/register",
  "/login",
  "/forgot-password",
  "/setup-password",
];
