import { ChannelEnum } from "@saas/shared/utils/^enum/channel/channel";

import { StorePromoChannelInterface } from "../../@interface/store/store-promo-channel/store-promo-channel.interface";

export type GetStorePromoAvailableChannelInput =
  ReadonlyArray<StorePromoChannelInterface>;

export type GetStorePromoAvailableChannelOutput = {
  availableChannel: ReadonlyArray<string>;
  availableCatalog: ReadonlyArray<{
    name: string;
    id: number;
  }>;
  availableMarketplace: ReadonlyArray<{
    name: string;
    id: number;
  }>;
};

export const getStorePromoAvailableChannel = (
  channels: GetStorePromoAvailableChannelInput
): GetStorePromoAvailableChannelOutput => {
  const isOflineChannel =
    channels.findIndex(({ channel }) => channel === ChannelEnum.OFFLINE) > -1;
  const availableCatalog = channels.reduce((acc, { channel, name, id }) => {
    if (channel === ChannelEnum.CATALOG) {
      return [...acc, { name, id }];
    }
    return acc;
  }, [] as { name: string; id: number }[]);
  const availableMarketplace = channels.reduce((acc, { channel, name, id }) => {
    if (
      [
        ChannelEnum.SHOPEE,
        ChannelEnum.TOKOPEDIA,
        ChannelEnum.TIKTOK,
        ChannelEnum.SHOPIFY,
      ].includes(channel)
    ) {
      return [...acc, { name, id }];
    }
    return acc;
  }, [] as { name: string; id: number }[]);
  const availableChannel = [];

  if (isOflineChannel) {
    availableChannel.push("Melaka Kasir");
  }

  const catalogLength = availableCatalog.length;
  const marketplaceLength = availableMarketplace.length;

  if (catalogLength > 0) {
    if (catalogLength > 3) {
      availableChannel.push(`Melaka Katalog (${catalogLength} Katalog)`);
    } else {
      availableChannel.push("Melaka Katalog:");
    }
  }

  if (marketplaceLength) {
    if (marketplaceLength > 3) {
      availableChannel.push(
        `Melaka Marketplace (${marketplaceLength} Marketplace)`
      );
    } else {
      availableChannel.push("Melaka Marketplace:");
    }
  }

  return {
    availableChannel,
    availableCatalog,
    availableMarketplace,
  };
};

export default getStorePromoAvailableChannel;
