"use client";

import { useId } from "react";

export type UseUniqueId = string;

export const useUniqueId = (key: string): UseUniqueId => {
  const id = useId();

  return `${key}-${id}`;
};

export default useUniqueId;
