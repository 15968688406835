import dayjs from "dayjs";
import "dayjs/locale/id";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";
import timezone from "dayjs/plugin/timezone";
import updateLocale from "dayjs/plugin/updateLocale";
import utc from "dayjs/plugin/utc";

dayjs.locale("id");
dayjs.extend(updateLocale);
dayjs.extend(relativeTime);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(duration);

dayjs.updateLocale("id", {
  weekdays: ["Minggu", "Senin", "Selasa", "Rabu", "Kamis", "Jum'at", "Sabtu"],
  months: [
    "Januari",
    "Februari",
    "Maret",
    "April",
    "Mei",
    "Juni",
    "Juli",
    "Agustus",
    "September",
    "Oktober",
    "November",
    "Desember",
  ],
  monthsShort: [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "Mei",
    "Jun",
    "Jul",
    "Agu",
    "Sep",
    "Okt",
    "Nov",
    "Des",
  ],
  relativeTime: {
    future: "in %s",
    past: "%s",
    s: "Baru saja",
    m: "Baru saja",
    mm: "%d Menit yang lalu",
    h: "1 Jam yang lalu",
    hh: "%d Jam yang lalu",
    d: "1 Hari yang lalu",
    dd: "%d Hari yang lalu",
    M: "1 Bulan yang lalu",
    MM: "%d Bulan yang lalu",
    y: "1 Tahun yang lalu",
    yy: "%d Tahun yang lalu",
  },
});

export { dayjs };
export default dayjs;
