export type ErrorResponseData = {
  error_code?: number;
  status_code: number;
  message: string;
};

export class FetchError<
  ErrorDataType extends ErrorResponseData = ErrorResponseData
> extends Error {
  private readonly ALLOWED_STATUS_CODES = [401, 403, 404];
  private readonly enableSentry =
    process.env["NEXT_PUBLIC_SENTRY_API_ERROR_TRACKING"] === "true";

  response: Response;
  data: ErrorDataType;

  constructor({
    message,
    response,
    payload,
    data,
  }: {
    message: string;
    response: Response;
    payload?: unknown;
    data?: ErrorDataType;
  }) {
    // Pass remaining arguments (including vendor specific ones) to error constructor
    super(message);

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, FetchError);
    }

    this.name = "FetchError";
    this.response = response;
    this.data =
      data ??
      ({ message: message, status_code: response.status } as ErrorDataType);

    if (this.enableSentry) this.sendToSentry(payload);
  }

  private get isUnexpectedError() {
    return !this.ALLOWED_STATUS_CODES.includes(this.response.status);
  }

  private async sendToSentry(payload: unknown) {
    if (this.isUnexpectedError) {
      const Sentry = await import("@sentry/nextjs");

      Sentry.captureException(this, {
        extra: { payload },
      });
    }
  }
}

export default FetchError;
