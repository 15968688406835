"use client";

import { useRouter } from "next/router";
import { useEffect, useState } from "react";

export interface UseProgress {
  key: number;
  isAnimating: boolean;
}

export const useProgress = (): UseProgress => {
  const [state, setState] = useState({
    isRouteChanging: false,
    loadingKey: 0,
  });

  const router = useRouter();

  useEffect(() => {
    const handleRouteChangeStart = () => {
      setState((prevState) => ({
        ...prevState,
        isRouteChanging: true,
        loadingKey: prevState.loadingKey ^ 1,
      }));
    };

    const handleRouteChangeEnd = () => {
      setState((prevState) => ({
        ...prevState,
        isRouteChanging: false,
      }));
    };

    router.events.on("routeChangeStart", handleRouteChangeStart);
    router.events.on("routeChangeComplete", handleRouteChangeEnd);
    router.events.on("routeChangeError", handleRouteChangeEnd);

    return () => {
      router.events.off("routeChangeStart", handleRouteChangeStart);
      router.events.off("routeChangeComplete", handleRouteChangeEnd);
      router.events.off("routeChangeError", handleRouteChangeEnd);
    };
  }, [router.events]);

  return {
    key: state.loadingKey,
    isAnimating: state.isRouteChanging,
  };
};

export default useProgress;
