import { QueryKey } from "@saas/account/config/query-key/query-key";
import { queryClient } from "@saas/core/react-query/query-client/query-client";

import { fetchSession } from "../../fetch-session/fetch-session";
import { UseSessionQueryData } from "./use-session.query";

import { Updater } from "@tanstack/react-query";

export const setUseSessionQuery = (
  updater: Updater<UseSessionQueryData | undefined, UseSessionQueryData>
): void => {
  queryClient.setQueryData<UseSessionQueryData>([QueryKey.SESSION], updater);
};

export const prefetchUseSessionQuery = async (
  cookie: string
): Promise<UseSessionQueryData | null> => {
  let data: UseSessionQueryData | null;

  try {
    data = await fetchSession(cookie);
  } catch {
    data = null;
  }

  await queryClient.prefetchQuery([QueryKey.SESSION], () => data);

  return data;
};

export const invalidateUseSessionQuery = () => {
  return queryClient.invalidateQueries([QueryKey.SESSION]);
};

export const removeUseSessionQuery = () => {
  return queryClient.removeQueries([QueryKey.SESSION]);
};
