"use client";

import { QueryKey } from "@saas/account/config/query-key/query-key";
import { FetchError } from "@saas/core/fetch/fetch-error/fetch-error";
import { QueryOptionsInterface } from "@saas/shared/utils/^interface/query-options/query-options.interface";

import fetchSession from "../../fetch-session/fetch-session";

import { Session } from "@ory/client";
import { useQuery } from "@tanstack/react-query";

export type UseSessionQueryData = Session;

export const useSessionQuery = <DerivedQueryData = UseSessionQueryData>(
  options?: QueryOptionsInterface<UseSessionQueryData, DerivedQueryData>
) => {
  return useQuery<UseSessionQueryData, FetchError, DerivedQueryData>(
    [QueryKey.SESSION],
    async () => {
      const data = await fetchSession();

      return data;
    },
    {
      ...options,
      select: options?.select,
      enabled: options?.enabled,
      retry: false,
      refetchOnMount: false,
      refetchOnWindowFocus: true,
    }
  );
};

export default useSessionQuery;
