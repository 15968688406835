import { GetServerSideProps, NextPage } from "next";
import App, { AppContext, AppProps } from "next/app";
import { useRouter } from "next/router";
import {
  Fragment,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import {
  invalidateLogoutUrlQuery,
  SESSION_COOKIE_KEY,
  triggerLogout,
  useLogoutUrlQuery,
} from "@saas/account/data-access";
import {
  invalidateUseSessionQuery,
  prefetchUseSessionQuery,
  removeUseSessionQuery,
} from "@saas/account/data-access/@query/session/use-session.query.utils";
import {
  Avatar,
  UnverifiedAccountBlockerModal,
  usePermissions,
  useSessionProfile,
} from "@saas/account/feature";
import { publicRoutes } from "@saas/config/dashboard";
import { env } from "@saas/config/shared";
import {
  initAmplitude,
  mediaQuery,
  queryClient,
  resetAmplitude,
  setAmplitudeUser,
} from "@saas/core";
import { prefetchUseFeatureFlagsStatusQuery } from "@saas/flags/data";
import { type FlagKeys } from "@saas/flags/utils";
import { FeatureFlagsProvider } from "@saas/layout/feature";
import { Navigation, PosLayout } from "@saas/layout/ui";
import { MarketplaceConnectionBlockerModal } from "@saas/marketplace-connection/feature";
import { OFFLINE_ORDER_KEY } from "@saas/order/utils";
import { AlertProvider, useAlert } from "@saas/shared/feature";
import {
  CashierMachineIcon,
  DraftOrderIcon,
  GeneralIcon,
  LeftIcon,
  LogoutIcon,
  OrdersIcon,
  UserIcon,
  WhatsappIcon,
} from "@saas/shared/icon";
import { ErrorBoundary, MenuItem, SlideOver } from "@saas/shared/ui";
import {
  ChannelEnum,
  classNames,
  createShareWhatsappLink,
  notify,
  useHasMounted,
} from "@saas/shared/utils";
import { useDraftOrderStorageQuery } from "@saas/store/feature";
import { OfflineNotification } from "@saas/store/ui";

import "./styles.css";

import {
  dehydrate,
  DehydratedState,
  Hydrate,
  QueryClientProvider,
} from "@tanstack/react-query";
import { getCookie } from "cookies-next";
import { ParsedUrlQuery } from "querystring";
import { useLocalStorage, useNetworkState } from "react-use";
import { useWindowSize } from "usehooks-ts";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type NextPageWithLayout<Props = Record<string, any>> =
  NextPage<Props> & {
    getLayout?: (
      page: ReactElement,
      pageProps: {
        dehydratedState: DehydratedState;
      } & Props,
      flags: Record<FlagKeys, boolean>
    ) => ReactElement;
  };

export type NextGetServerSideProps<
  Props = Record<string, unknown>,
  Query extends ParsedUrlQuery = ParsedUrlQuery
> = GetServerSideProps<Props & { dehydratedState: DehydratedState }, Query>;

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

export const PosAccountMenu = ({ onLogout }: { onLogout: () => void }) => {
  const [openSlideOver, setOpenSlideOver] = useState(false);

  const menuItems = [
    {
      as: "button",
      label: "Ke Landing Page",
      icon: GeneralIcon,
      testid: "home__dropdown-menu__landing-page",
      onClick: () =>
        navigator.share({
          url: env.LANDING_URL + "?view",
        }),
    },
    {
      as: "button",
      label: "Edit Profil",
      icon: UserIcon,
      testid: "home__dropdown-menu__edit-profile",
      onClick: () =>
        navigator.share({
          url: env.DASHBOARD_URL + "/account/profile",
        }),
    },
    {
      as: "button",
      label: "Keluar",
      icon: LogoutIcon,
      divider: true,
      testid: "home__dropdown-menu__keluar",
      onClick: onLogout,
    },
  ];

  const handleCloseSlideOver = () => setOpenSlideOver(false);

  return (
    <div>
      <button
        className={"rounded focus:outline-none"}
        data-testid={"home__menu__my-account"}
        onClick={() => setOpenSlideOver(true)}
      >
        <Avatar />
      </button>
      <SlideOver
        origin={"right"}
        open={openSlideOver}
        onClose={() => setOpenSlideOver(false)}
      >
        <div className={"flex items-center gap-4 py-4 px-5"}>
          <button
            className={
              "text-le hover:text-he rounded focus:outline-none focus:ring"
            }
            onClick={handleCloseSlideOver}
          >
            <LeftIcon className={"h-6 w-6"} />
          </button>

          <h2 className={"text-he title-large uppercase"}>akunku</h2>
        </div>
        <div className={"flex flex-col"}>
          {menuItems.map((item, index) => (
            <button
              key={index}
              onClick={() => {
                item.onClick();

                handleCloseSlideOver();
              }}
              className={classNames(
                "group focus:outline-none",
                item.divider && "border-neutral-N200 border-t"
              )}
              data-testid={item.testid}
            >
              <MenuItem className={"py-4 px-5 md:mx-0"} icon={item.icon}>
                {item.label}
              </MenuItem>
            </button>
          ))}
        </div>
      </SlideOver>
    </div>
  );
};

const PointOfSalesLayout = ({ page }: { page: ReactElement }) => {
  const { push, reload, asPath } = useRouter();
  const { online = true } = useNetworkState();
  const [, dispatch] = useAlert();
  const { width } = useWindowSize();
  const offlineOrders = useLocalStorage(OFFLINE_ORDER_KEY);
  const whatsappLink = createShareWhatsappLink({
    tel: env.SUPPORT_PHONE_NO,
    text: "Halo admin, saya butuh bantuan kasir",
  });

  const draftOrderPath = "/#draftOrder";

  const isMobile = width < mediaQuery.md;
  const removeOfflineOrders = offlineOrders[2];

  const { profile } = useSessionProfile();
  const { data: logoutToken, error: logoutError } = useLogoutUrlQuery({
    enabled: profile?.isActive ?? false,
  });

  const { data: draftOrders } = useDraftOrderStorageQuery();
  const isDataDraftOrderExist = draftOrders?.length > 0;

  const { hasPermission, hasParentId } = usePermissions();

  const isPublicRoute = asPath.includes("/public");

  useEffect(() => {
    if (!isPublicRoute) {
      setAmplitudeUser(profile?.id, {
        app_name: "Melaka Kasir - Aplikasi POS",
      });
    }
  }, [isPublicRoute, profile?.id]);

  useEffect(() => {
    if (logoutError && !isPublicRoute) {
      removeUseSessionQuery();
      push(env.APP_URL + "/login");
    }
  }, [isPublicRoute, logoutError, push]);

  const handleLogout = useCallback(async () => {
    if (logoutToken)
      try {
        await triggerLogout({ token: logoutToken });

        removeOfflineOrders();

        await invalidateUseSessionQuery();

        resetAmplitude();

        reload();
      } catch (error) {
        await invalidateLogoutUrlQuery();

        notify(dispatch, {
          content: "Gagal keluar, silakan coba lagi.",
          variant: "negative",
        });
      }
  }, [dispatch, logoutToken, reload, removeOfflineOrders]);

  const navigation: ReadonlyArray<Navigation> = [
    {
      label: "Kasir",
      href: "/",
      icon: CashierMachineIcon,
      testid: "home__menu__kasir",
      enabled: true,
    },
    {
      label: "Draft Order Kasir",
      href: draftOrderPath,
      icon: DraftOrderIcon,
      testid: "home__menu__draft-order",
      enabled: true,
      disabled: !isDataDraftOrderExist,
    },
    {
      label: "Order",
      href: `/?shopId=${ChannelEnum.OFFLINE}#orderView`,
      icon: OrdersIcon,
      testid: "home__menu__order-list",
      enabled: true,
    },
    {
      label: "Keluar",
      icon: LogoutIcon,
      testid: "home__menu__logout",
      enabled: isMobile,
      gap: true,
      onClick: handleLogout,
    },
    {
      label: "Customer Support",
      href: whatsappLink,
      target: "_blank",
      icon: WhatsappIcon,
      testid: "home__menu__customer-support",
      enabled: true,
    },
  ];

  const hasMounted = useHasMounted();
  if (!hasMounted) return null;

  return (
    <>
      {!online ? <OfflineNotification /> : null}

      {!isPublicRoute ? (
        <PosLayout
          marketplaceConnectionBlockerModal={MarketplaceConnectionBlockerModal}
          unverifiedAccountBlockerModal={UnverifiedAccountBlockerModal}
          navigation={navigation}
          accountMenu={() => <PosAccountMenu onLogout={handleLogout} />}
          isProfileLoaded={!!profile}
          accesses={profile?.accesses}
          hasAccess={hasPermission(asPath, "pos")}
          hasParentId={hasParentId}
          handleLogout={handleLogout}
        >
          {page}
        </PosLayout>
      ) : (
        <Fragment>{page}</Fragment>
      )}
    </>
  );
};

const PointOfSalesApp = ({ Component, pageProps }: AppPropsWithLayout) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const client = useMemo(() => queryClient, []);

  useEffect(() => {
    initAmplitude();
  }, []);

  const getLayout = useCallback(
    (page, pageProps) => {
      return (
        <QueryClientProvider client={client}>
          <FeatureFlagsProvider>
            {(flags) => (
              <Hydrate state={pageProps.dehydratedState}>
                <AlertProvider>
                  {Component.getLayout ? (
                    Component.getLayout(page, pageProps, flags)
                  ) : (
                    <PointOfSalesLayout page={page} />
                  )}
                </AlertProvider>
              </Hydrate>
            )}
          </FeatureFlagsProvider>
        </QueryClientProvider>
      );
    },
    [Component, client]
  );

  return getLayout(
    <ErrorBoundary>
      <Component {...pageProps} />
    </ErrorBoundary>,
    pageProps
  );
};

PointOfSalesApp.getInitialProps = async (context: AppContext) => {
  const { req, res } = context.ctx;
  const isServer = req && res;

  if (isServer) {
    const sessionCookieValue = getCookie(SESSION_COOKIE_KEY, {
      req,
      res,
    }) as string;

    if (!sessionCookieValue) {
      const url = new URL(req.url, env.APP_URL);
      const isPublicRoute = [...publicRoutes].includes(url.pathname);
      const isPublicPath = url.pathname.includes("/public");

      if (!isPublicRoute && !isPublicPath) {
        removeUseSessionQuery();
        res.writeHead(307, {
          Location: "/login",
        });

        res.end();
      }
    }

    await Promise.allSettled([
      prefetchUseSessionQuery(sessionCookieValue),
      prefetchUseFeatureFlagsStatusQuery(),
    ]);
  }

  const appProps = await App.getInitialProps(context);

  return {
    ...appProps,
    pageProps: {
      ...appProps.pageProps,
      dehydratedState: dehydrate(queryClient),
    },
  };
};

export default PointOfSalesApp;
