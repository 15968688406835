import { ResponseInterface } from "@saas/core/^interface/response/response.interface";
import { fetchJson } from "@saas/core/fetch/fetch-json/fetch-json";
import { FeatureFlagsInterface } from "@saas/flags/utils/@interface/feature-flags/feature-flags.interface";
import { FlagKeys } from "@saas/flags/utils/@type/flag-keys/flag-keys";

export interface GetFeatureFlagsStatusResponse extends ResponseInterface {
  flags: FeatureFlagsInterface["status"];
}

export type GetFeatureFlagsStatusOutput = Record<FlagKeys, boolean>;

export const getFeatureFlagsStatus = async (
  options?: RequestInit
): Promise<GetFeatureFlagsStatusOutput> => {
  const response = await fetchJson<GetFeatureFlagsStatusResponse>(
    "/api/feature-flags/status",
    options
  );

  return response.flags;
};

export default getFeatureFlagsStatus;
