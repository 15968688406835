import { env } from "@saas/config/shared/env/env";

import { AccessesKeyEnum } from "../@enum/subaccount/accesses-key/accesses-key.enum";

import { Session } from "@ory/client";

export interface SessionProfile {
  id: string;
  name: string;
  email: string;
  phone: string;
  emailVerified: boolean;
  whatsappVerified: boolean;
  isActive: boolean;
  accesses: AccessesKeyEnum[];
  parentUserId: string | null;
  birthdate: Date | null;
  isLoyaltyUser: boolean;
}

export interface SessionMetadata {
  accesses: AccessesKeyEnum[];
  parent_user_id: string | null;
  whatsapp_verified: boolean;
  recovery_from?: string;
  registered_on?: string;
}

export const getProfileFromSession = (
  session?: Session
): SessionProfile | null => {
  if (!session) {
    return null;
  }

  let metadata: SessionMetadata = {
    accesses: [],
    parent_user_id: null,
    whatsapp_verified: false,
  };

  if (session.identity.metadata_public) {
    metadata = session.identity.metadata_public as SessionMetadata;
  }

  let isLoyalty = false;
  if (metadata.registered_on) {
    const baseDomain = new URL(env.LOYALTY_URL).hostname;
    isLoyalty = metadata.registered_on.includes(baseDomain);
  }

  return {
    id: session.identity.id,
    name: session.identity.traits.name,
    email: session.identity.traits.email,
    phone: session.identity.traits.phone,
    emailVerified: session.identity.verifiable_addresses?.[0].verified ?? false,
    whatsappVerified: metadata.whatsapp_verified ?? false,
    isActive: session.active ?? false,
    accesses: metadata.accesses,
    parentUserId: metadata.parent_user_id,
    birthdate: session.identity.traits.birthdate
      ? new Date(session.identity.traits.birthdate)
      : null,
    isLoyaltyUser: isLoyalty,
  };
};

export default getProfileFromSession;
