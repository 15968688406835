"use client";

import {
  invalidateMarketplaceConnectionsQuery as invalidateMarketplaceConnectionsQueryShared,
  invalidateMarketplaceConnectionsQueryV2 as invalidateMarketplaceConnectionsQueryV2Shared,
  prefetchMarketplaceConnectionsQuery as prefetchMarketplaceConnectionsQueryShared,
  prefetchMarketplaceConnectionsWithMetadataQuery as prefetchMarketplaceConnectionsWithMetadataQueryShared,
  useConnectedMarketplaceConnectionsQuery as useConnectedMarketplaceConnectionsQueryShared,
  useConnectedOrExpiredMarketplaceConnectionsQuery as useConnectedOrExpiredMarketplaceConnectionsQueryShared,
  useConnectedOrPendingMarketplaceConnectionsQuery as useConnectedOrPendingMarketplaceConnectionsQueryShared,
  useExpiredMarketplaceConnectionsQuery as useExpiredMarketplaceConnectionsQueryShared,
  useGetMarketplaceConnectionsQueryV2 as useGetMarketplaceConnectionsQueryV2Shared,
  UseMarketplaceConnectionListQueryData as UseMarketplaceConnectionListQueryDataShared,
  UseMarketplaceConnectionListQueryParams as UseMarketplaceConnectionListQueryParamsShared,
  useMarketplaceConnectionsQuery as useMarketplaceConnectionsQueryShared,
} from "@saas/shared/data/@query/marketplace-connection/marketplace-connection-query/use-marketplace-connections-query";

export type UseMarketplaceConnectionListQueryParams =
  UseMarketplaceConnectionListQueryParamsShared;

export type UseMarketplaceConnectionListQueryData =
  UseMarketplaceConnectionListQueryDataShared;

export const useMarketplaceConnectionsQuery =
  useMarketplaceConnectionsQueryShared;

export const useGetMarketplaceConnectionsQueryV2 =
  useGetMarketplaceConnectionsQueryV2Shared;

export const useConnectedMarketplaceConnectionsQuery =
  useConnectedMarketplaceConnectionsQueryShared;

export const useExpiredMarketplaceConnectionsQuery =
  useExpiredMarketplaceConnectionsQueryShared;

export const useConnectedOrPendingMarketplaceConnectionsQuery =
  useConnectedOrPendingMarketplaceConnectionsQueryShared;

export const useConnectedOrExpiredMarketplaceConnectionsQuery =
  useConnectedOrExpiredMarketplaceConnectionsQueryShared;

export const prefetchMarketplaceConnectionsQuery =
  prefetchMarketplaceConnectionsQueryShared;

export const prefetchMarketplaceConnectionsWithMetadataQuery =
  prefetchMarketplaceConnectionsWithMetadataQueryShared;

export const invalidateMarketplaceConnectionsQuery =
  invalidateMarketplaceConnectionsQueryShared;

export const invalidateMarketplaceConnectionsQueryV2 =
  invalidateMarketplaceConnectionsQueryV2Shared;

export default useMarketplaceConnectionsQuery;
