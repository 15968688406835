"use client";

import { createQueryClient, FetchError, queryClient } from "@saas/core";
import { QueryOptionsInterface } from "@saas/shared/utils";

import {
  featureFlagsQueryKey,
  getFeatureFlagsStatus,
  GetFeatureFlagsStatusOutput,
} from "../..";

import { Updater, useQuery } from "@tanstack/react-query";

export type UseFeatureFlagsStatusQueryData = GetFeatureFlagsStatusOutput;

export const useFeatureFlagsStatusQuery = <
  DerivedQueryData = UseFeatureFlagsStatusQueryData
>(
  options?: QueryOptionsInterface<
    UseFeatureFlagsStatusQueryData,
    DerivedQueryData
  >
) => {
  return useQuery<UseFeatureFlagsStatusQueryData, FetchError, DerivedQueryData>(
    featureFlagsQueryKey.status(),
    async () => {
      const data = await getFeatureFlagsStatus();

      return data;
    },
    {
      ...options,
      select: options?.select,
      enabled: options?.enabled,
    }
  );
};

/**
 *
 * @deprecated
 * don't use barrel import, use "@saas/flags/data/@query/feature-flags-status/use-feature-flags-status.query.utils";
 */
export const setUseFeatureFlagsStatusQuery = (
  updater: Updater<
    UseFeatureFlagsStatusQueryData | undefined,
    UseFeatureFlagsStatusQueryData
  >
): void => {
  queryClient.setQueryData<UseFeatureFlagsStatusQueryData>(
    featureFlagsQueryKey.status(),
    updater
  );
};

/**
 *
 * @deprecated
 * don't use barrel import, use "@saas/flags/data/@query/feature-flags-status/use-feature-flags-status.query.utils";
 */
export const prefetchUseFeatureFlagsStatusQuery =
  async (): Promise<UseFeatureFlagsStatusQueryData> => {
    const client = createQueryClient();

    const data = await getFeatureFlagsStatus();

    await client.prefetchQuery(featureFlagsQueryKey.status(), () => data);

    return data;
  };

/**
 *
 * @deprecated
 * don't use barrel import, use "@saas/flags/data/@query/feature-flags-status/use-feature-flags-status.query.utils";
 */
export const invalidateUseFeatureFlagsStatusQuery = () => {
  return queryClient.invalidateQueries(featureFlagsQueryKey.status());
};

export default useFeatureFlagsStatusQuery;
