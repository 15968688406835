"use client";

import Image from "next/legacy/image";
import { useRouter } from "next/router";
import { useMemo } from "react";

// TODO: move reseller registration to account
// eslint-disable-next-line @nx/enforce-module-boundaries
import { useSessionProfile } from "@saas/account/feature";
import { marketplaceConnectionRoutes } from "@saas/config/dashboard";
import { env } from "@saas/config/shared";
import { useMarketplaceConnectionsQuery } from "@saas/marketplace-connection/data-access";
import { MarketplaceListStatusEnum } from "@saas/marketplace-connection/utils";
import { AddIcon } from "@saas/shared/icon";
import { BaseButton, ContainedModal, Typography } from "@saas/shared/ui";

import { useMediaQuery } from "usehooks-ts";

export const MarketplaceConnectionBlockerModal = () => {
  const isStandaloneView = useMediaQuery("(display-mode: standalone)");
  const isPOS = isStandaloneView && env.APP_URL.includes(env.POS_URL);

  const { profile } = useSessionProfile();
  const testid = "no-connection-popup";

  const { push, asPath } = useRouter();

  const { data: hasNoMarketplaces } = useMarketplaceConnectionsQuery({
    select: (data) =>
      !data.some(
        (marketplace) =>
          marketplace.status === MarketplaceListStatusEnum.CONNECTED
      ),
  });

  const isOpen = useMemo(() => {
    const path = "/" + asPath.split("/")[1];

    const requireMarketplace = marketplaceConnectionRoutes.some(
      (route) => route === path
    );

    return (
      (profile?.emailVerified && requireMarketplace && hasNoMarketplaces) ??
      false
    );
  }, [asPath, hasNoMarketplaces, profile?.emailVerified]);

  const handleRedirectToMarketplaceConnections = async () => {
    const marketplaceConnectionPathname = "/account/marketplace-connections";

    if (isPOS) {
      const marketplaceConnectionUrl =
        env.DASHBOARD_URL + marketplaceConnectionPathname;

      navigator.share({
        url: marketplaceConnectionUrl,
      });

      return;
    }

    await push(marketplaceConnectionPathname);
  };

  return (
    <ContainedModal size={"medium"} open={isOpen}>
      <div className={"flex flex-col items-center p-4 text-center"}>
        <Image
          width={300}
          height={200}
          src={"/images/marketplace-connection/no-marketplace-connection.svg"}
          alt={"Belum ada toko online terkoneksi icon"}
        />

        <Typography
          as={"h3"}
          type={"headline-h2"}
          className={"mt-6 mb-2"}
          data-testid={testid + "__text__title"}
        >
          Belum ada toko online terkoneksi
        </Typography>

        <Typography
          as={"p"}
          className={"mb-6"}
          data-testid={testid + "__text__content"}
        >
          Koneksikan toko online untuk menampilkan data toko online Kamu.
        </Typography>

        <div>
          <BaseButton
            variant={"secondary"}
            icon={AddIcon}
            onClick={handleRedirectToMarketplaceConnections}
            testid={testid + "__button__goto-mp-connection"}
          >
            <Typography type={"button-large"}>
              Tambah Koneksi Toko Online
            </Typography>
          </BaseButton>
        </div>
      </div>
    </ContainedModal>
  );
};

export default MarketplaceConnectionBlockerModal;
