"use client";

import { useSessionQuery } from "@saas/account/data-access/@query/session/use-session.query";
import {
  getProfileFromSession,
  SessionProfile,
} from "@saas/account/utils/get-profile-from-session/get-profile-from-session";
import {
  ErrorResponseData,
  FetchError,
} from "@saas/core/fetch/fetch-error/fetch-error";

export type UseSessionProfileReturnType = {
  profile: SessionProfile | null;
  error: FetchError<ErrorResponseData> | null;
  refetch: ReturnType<typeof useSessionQuery>["refetch"];
};

export const useSessionProfile = (
  enabled?: boolean
): UseSessionProfileReturnType => {
  const {
    data: profile = null,
    error,
    refetch,
  } = useSessionQuery({
    select: (data) => getProfileFromSession(data),
    enabled,
  });

  return { profile, error, refetch };
};

export default useSessionProfile;
