"use client";

import { FetchError, queryClient } from "@saas/core";
import { MarketplaceListStatusEnum } from "@saas/shared/utils/@enum/marketplace-list-status/marketplace-list-status.enum";
import { MarketplaceTypeEnum } from "@saas/shared/utils/@enum/marketplace-type/marketplace-type.enum";
import { MarketplaceConnectionInterface } from "@saas/shared/utils/@interface/marketplace-connection/marketplace-connection.interface";
import { QueryOptionsInterface } from "@saas/shared/utils/^interface/query-options/query-options.interface";
import { marketplaceConnectionQueryKeys } from "@saas/shared/utils/marketplace-connection-query-keys/marketplace-connection-query-keys";

import getMarketplaceConnections, {
  GetMarketplaceConnectionListInput,
  GetMarketplaceConnectionListOutput,
} from "../../../marketplace-connection/get-marketplace-connections/get-marketplace-connections";

import { useQuery } from "@tanstack/react-query";

export type UseMarketplaceConnectionListQueryParams =
  GetMarketplaceConnectionListInput;
export type UseMarketplaceConnectionListQueryData =
  ReadonlyArray<MarketplaceConnectionInterface>;
export const useMarketplaceConnectionsQuery = <
  DerivedQueryData = UseMarketplaceConnectionListQueryData
>(
  options?: QueryOptionsInterface<
    UseMarketplaceConnectionListQueryData,
    DerivedQueryData
  >
) => {
  return useQuery<
    UseMarketplaceConnectionListQueryData,
    FetchError,
    DerivedQueryData
  >(
    marketplaceConnectionQueryKeys.all(),
    async () => {
      const response = await getMarketplaceConnections();
      return response.data;
    },
    {
      select: options?.select,
      enabled: options?.enabled,
    }
  );
};
export const useGetMarketplaceConnectionsQueryV2 = <
  DerivedQueryData = GetMarketplaceConnectionListOutput
>(
  options?: QueryOptionsInterface<
    GetMarketplaceConnectionListOutput,
    DerivedQueryData
  >,
  params?: UseMarketplaceConnectionListQueryParams
) => {
  return useQuery<
    GetMarketplaceConnectionListOutput,
    FetchError,
    DerivedQueryData
  >(
    marketplaceConnectionQueryKeys.all({
      metadata: true,
      connectionType: params?.connectionType ?? [MarketplaceTypeEnum.SHOP],
    }),
    () => getMarketplaceConnections(params),
    options
  );
};
export const useConnectedMarketplaceConnectionsQuery = () => {
  return useMarketplaceConnectionsQuery({
    select: (data) => {
      return data.filter(
        (marketplaceConnection) =>
          marketplaceConnection.status === MarketplaceListStatusEnum.CONNECTED
      );
    },
  });
};
export const useExpiredMarketplaceConnectionsQuery = () => {
  return useMarketplaceConnectionsQuery({
    select: (data) => {
      return data.filter(
        ({ status }) => status === MarketplaceListStatusEnum.EXPIRED
      );
    },
  });
};
export const useConnectedOrPendingMarketplaceConnectionsQuery = () => {
  return useMarketplaceConnectionsQuery({
    select: (data) => {
      return data.filter(
        ({ status }) =>
          status === MarketplaceListStatusEnum.CONNECTED ||
          status === MarketplaceListStatusEnum.PENDING
      );
    },
  });
};
export const useConnectedOrExpiredMarketplaceConnectionsQuery = () => {
  return useMarketplaceConnectionsQuery({
    select: (data) => {
      return data.filter(
        ({ status }) =>
          status === MarketplaceListStatusEnum.CONNECTED ||
          status === MarketplaceListStatusEnum.EXPIRED
      );
    },
  });
};
export const prefetchMarketplaceConnectionsQuery = async (
  cookie: string
): Promise<UseMarketplaceConnectionListQueryData | null> => {
  let data: ReadonlyArray<MarketplaceConnectionInterface> | null;
  try {
    const response = await getMarketplaceConnections(undefined, {
      headers: { cookie },
    });
    data = response.data;
  } catch {
    data = null;
  }
  await queryClient.prefetchQuery(
    marketplaceConnectionQueryKeys.all(),
    () => data
  );
  return data;
};
export const prefetchMarketplaceConnectionsWithMetadataQuery = async (
  params: UseMarketplaceConnectionListQueryParams,
  cookie: string
) => {
  let data: GetMarketplaceConnectionListOutput | null;
  try {
    data = await getMarketplaceConnections(params, { headers: { cookie } });
  } catch {
    data = null;
  }
  await queryClient.prefetchQuery(
    marketplaceConnectionQueryKeys.all({
      metadata: true,
      connectionType: params?.connectionType ?? [MarketplaceTypeEnum.SHOP],
    }),
    () => data
  );
  return data;
};
export const invalidateMarketplaceConnectionsQuery = () => {
  return queryClient.invalidateQueries(marketplaceConnectionQueryKeys.all());
};
export const invalidateMarketplaceConnectionsQueryV2 = () => {
  return queryClient.invalidateQueries(
    marketplaceConnectionQueryKeys.all({ metadata: true })
  );
};
export default useMarketplaceConnectionsQuery;
